import { createSlice } from '@reduxjs/toolkit';
import { CasaAziri } from 'modules/casa-aziri/types';

type CasaAziriStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: CasaAziri
}

const initialState: CasaAziriStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{
      idCasaAziri:'',
      numero:0,
      valor:'',
      nome:'',
      selecionado:'false',
      dataPagamento:'',
      confirmado:'false',
      dataConfirmacao:''        
    }
}

export const casaAziriSlice = createSlice({
    name: 'casaAziri',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        updateSelecao: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        setCompletedStep(state, action) {
            state.completedStep = action.payload
        },
    }
})

export const casaAziriActions = casaAziriSlice.actions
export default casaAziriSlice.reducer