import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.produtos

const values = createSelector(state, produtos => produtos.values)
const origin = createSelector(state, produtos => produtos.origin)

export const produtosSelector = {    
    values,
    origin
  }