import React, { createContext, lazy, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { find, map } from 'lodash'

import AuthenticateScreenRouter from './ScreenRouter/AuthenticateScreenRouter'
import { RouteType } from './ScreenRouter/RouterSwitch/RouterSwitch'
const Dashboard = lazy(() => import('modules/home/screens/Dashboard'))

interface Routes {
  currentPath: string
  currentRoute?: RouteType
  routes: Record<string, RouteType>
}

export const RoutesContext = createContext<Routes>({ currentPath: '', routes: {} })

export const useRoutes = () => useContext(RoutesContext)

const AuthenticatedRoutesProvider = () => {
  const { pathname: currentPath } = useLocation()

  const routes = useMemo(
    () => ({
      home: {
        path: '/',
        Component: Dashboard,
      },
      consulta: {
        path: '/consulta',
        Component: lazy(() => import('modules/Consulta/pages/ConsultaPrincipal')),
      },
      favoritarLojaFavorita: {
        path: '/loja-favorita/favoritar',
        Component: lazy(() => import('modules/auth/screens/LojaFavorita/LojaFavorita')),
      },
      trocarLoja: {
        path: '/loja-favorita/trocar',
        Component: lazy(() => import('modules/auth/screens/LojaFavorita/LojaFavorita')),
      },
      usuarioCadastrar: {
        path: '/usuario/cadastrar',
        Component: lazy(() => import('modules/usuarios/pages/UsuarioCadastrar')),
      },
      usuarioEditar: {
        path: '/usuario/editar',
        Component: lazy(() => import('modules/usuarios/pages/UsuarioEditar')),
      },
      usuarioListar: {
        path: '/usuario/listar',
        Component: lazy(() => import('modules/usuarios/pages/UsuarioListar')),
      },
      usuarioVisualizar: {
        path: '/usuario/visualizar',
        Component: lazy(() => import('modules/usuarios/pages/UsuarioVisualizar')),
      },

      lojaCadastrar: {
        path: '/lojas/cadastrar',
        Component: lazy(() => import('modules/loja/pages/LojaCadastrar')),
      },
      lojaEditar: {
        path: '/lojas/editar',
        Component: lazy(() => import('modules/loja/pages/LojaEditar')),
      },
      lojaListar: {
        path: '/lojas/listar',
        Component: lazy(() => import('modules/loja/pages/LojaListar')),
      },
      lojaVisualizar: {
        path: '/lojas/visualizar',
        Component: lazy(() => import('modules/loja/pages/LojaVisualizar')),
      },

      bancoCadastrar: {
        path: '/bancos/cadastrar',
        Component: lazy(() => import('modules/banco/pages/BancoCadastrar')),
      },
      bancoEditar: {
        path: '/bancos/editar',
        Component: lazy(() => import('modules/banco/pages/BancoEditar')),
      },
      bancoListar: {
        path: '/bancos/listar',
        Component: lazy(() => import('modules/banco/pages/BancoListar')),
      },
      bancoVisualizar: {
        path: '/bancos/visualizar',
        Component: lazy(() => import('modules/banco/pages/BancoVisualizar')),
      },

      fornecedoresCadastrar: {
        path: '/fornecedores/cadastrar',
        Component: lazy(() => import('modules/fornecedores/pages/FornecedoresCadastrar')),
      },
      fornecedoresEditar: {
        path: '/fornecedores/editar',
        Component: lazy(() => import('modules/fornecedores/pages/FornecedoresEditar')),
      },
      fornecedoresListar: {
        path: '/fornecedores/listar',
        Component: lazy(() => import('modules/fornecedores/pages/FornecedoresListar')),
      },
      fornecedoresVisualizar: {
        path: '/fornecedores/visualizar',
        Component: lazy(() => import('modules/fornecedores/pages/FornecedoresVisualizar')),
      },

      grupoProdutosCadastrar: {
        path: '/grupo-produtos/cadastrar',
        Component: lazy(() => import('modules/grupo-produtos/pages/GrupoProdutosCadastrar')),
      },
      grupoProdutosEditar: {
        path: '/grupo-produtos/editar',
        Component: lazy(() => import('modules/grupo-produtos/pages/GrupoProdutosEditar')),
      },
      grupoProdutosListar: {
        path: '/grupo-produtos/listar',
        Component: lazy(() => import('modules/grupo-produtos/pages/GrupoProdutosListar')),
      },
      grupoProdutosVisualizar: {
        path: '/grupo-produtos/visualizar',
        Component: lazy(() => import('modules/grupo-produtos/pages/GrupoProdutosVisualizar')),
      },

      produtosCadastrar: {
        path: '/produtos/cadastrar',
        Component: lazy(() => import('modules/produtos/pages/ProdutosCadastrar')),
      },
      produtosEditar: {
        path: '/produtos/editar',
        Component: lazy(() => import('modules/produtos/pages/ProdutosEditar')),
      },
      produtosListar: {
        path: '/produtos/listar',
        Component: lazy(() => import('modules/produtos/pages/ProdutosListar')),
      },
      produtosVisualizar: {
        path: '/produtos/visualizar',
        Component: lazy(() => import('modules/produtos/pages/ProdutosVisualizar')),
      },

      atividadeNaoProcedimentalCadastrar: {
        path: '/atividade-nao-procedimental/cadastrar',
        Component: lazy(() => import('modules/atividade-nao-procedimental/pages/AtividadeNaoProcedimentalCadastrar')),
      },
      atividadeNaoProcedimentalEditar: {
        path: '/atividade-nao-procedimental/editar',
        Component: lazy(() => import('modules/atividade-nao-procedimental/pages/AtividadeNaoProcedimentalEditar')),
      },
      atividadeNaoProcedimentalDocumento: {
        path: '/atividade-nao-procedimental/documento',
        Component: lazy(() => import('modules/atividade-nao-procedimental/pages/AtividadeNaoProcedimentalDocumento')),
      },
      atividadeNaoProcedimentalVisualizar: {
        path: '/atividade-nao-procedimental/visualizar',
        Component: lazy(() => import('modules/atividade-nao-procedimental/pages/AtividadeNaoProcedimentalVisualizar')),
      },
      atividadeNaoProcedimentalListar: {
        path: '/atividade-nao-procedimental/listar',
        Component: lazy(() => import('modules/atividade-nao-procedimental/pages/AtividadeNaoProcedimentalListar')),
      },
      settings: {
        path: '/configuracao-sistema',
        Component: lazy(() => import('modules/admin/settings/screen/SettingList')),
      },
      pessoaFisicaCadastrar: {
        path: '/pessoa-fisica/cadastrar',
        Component: lazy(() => import('modules/pessoa-fisica/pages/PessoaFisicaCadastrar')),
      },
      pessoaFisicaEditar: {
        path: '/pessoa-fisica/editar',
        Component: lazy(() => import('modules/pessoa-fisica/pages/PessoaFisicaEditar')),
      },
      pessoaFisicaVisualizar: {
        path: '/pessoa-fisica/visualizar',
        Component: lazy(() => import('modules/pessoa-fisica/pages/PessoaFisicaVisualizar')),
      },
      pessoaFisicaListar: {
        path: '/pessoa-fisica/listar',
        Component: lazy(() => import('modules/pessoa-fisica/pages/PessoaFisicaListar')),
      },
      pessoaJuridicaCadastrar: {
        path: '/pessoa-juridica/cadastrar',
        Component: lazy(() => import('modules/pessoa-juridica/pages/PessoaJuridicaCadastrar')),
      },
      pessoaJuridicaEditar: {
        path: '/pessoa-juridica/editar',
        Component: lazy(() => import('modules/pessoa-juridica/pages/PessoaJuridicaEditar')),
      },
      pessoaJuridicaVisualizar: {
        path: '/pessoa-juridica/visualizar',
        Component: lazy(() => import('modules/pessoa-juridica/pages/PessoaJuridicaVisualizar')),
      },
      pessoaJuridicaListar: {
        path: '/pessoa-juridica/listar',
        Component: lazy(() => import('modules/pessoa-juridica/pages/PessoaJuridicaListar')),
      },
      processoProcedimentoCadastrar: {
        path: '/processo-procedimento/cadastrar',
        Component: lazy(() => import('modules/processo-procedimento/pages/ProcessoProcedimentoCadastrar')),
      },
      processoProcedimentoDetalhar: {
        path: '/processo-procedimento/detalhar',
        Component: lazy(() => import('modules/processo-procedimento/pages/ProcessoProcedimentoDetalhar')),
      },
      processoProcedimentoAcervo: {
        path: '/processo-procedimento/acervo',
        Component: lazy(() => import('modules/processo-procedimento/pages/ProcessoProcedimentoAcervo')),
      },
      equipeDistribuicaoListar: {
        path: '/equipe-distribuicao/listar',
        Component: lazy(() => import('modules/equipe-distribuicao/pages/EquipeDistribuicaoListar')),
      },
      equipeDistribuicaoCadastrar: {
        path: '/equipe-distribuicao/cadastrar',
        Component: lazy(() => import('modules/equipe-distribuicao/pages/EquipeDistribuicaoCadastrar')),
      },
      equipeDistribuicaoEditar: {
        path: '/equipe-distribuicao/editar/:id',
        Component: lazy(() => import('modules/equipe-distribuicao/pages/EquipeDistribuicaoEditar')),
      },
    }),
    []
  )

  const currentRoute: RouteType | undefined = useMemo(
    () =>
      find(
        map(routes, item => item),
        { path: currentPath }
      ),
    [routes, currentPath]
  )

  const toolkit = useMemo(
    () => ({
      routes,
      currentRoute,
      currentPath,
    }),
    [routes, currentRoute, currentPath]
  )

  return (
    <RoutesContext.Provider value={toolkit}>
      <AuthenticateScreenRouter />
    </RoutesContext.Provider>
  )
}

export default AuthenticatedRoutesProvider
