import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import MenuDrawer from 'containers/MenuDrawer/MenuDrawer';
import Topbar from 'containers/topbar/Topbar';
import PermissionsSelector from 'store/permissions/selectors';

import { getMenuOptions } from './menu';
import { Content, Container, Main } from './styles';

// Custom Hook para verificar permissões
const usePermission = (url: string) => 
  useSelector(PermissionsSelector.getPermissionsByUrl(url)).length > 0;


const Authenticated: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);

  // Definição de permissões e rotas
  const permissionRoutes = [
    { route: '/administrativo', check: usePermission('/administrativo') },
    { route: '/financeiro', check: usePermission('/financeiro') },
    { route: '/usuario/listar', check: usePermission('/usuario/listar') },
    { route: '/permissoes/listar', check: usePermission('/permissoes/listar') },
    { route: '/bancos/listar', check: usePermission('/bancos/listar') },
    { route: '/clientes/listar', check: usePermission('/clientes/listar') },
    { route: '/produtos', check: true },
    { route: '/consulta', check: true },
    { route: '/vendas', check: true },
    { route: '/casa-aziri', check: true },
    { route: '/combos/listar', check: usePermission('/combos/listar') },
    { route: '/configuracao-sistema', check: usePermission('/configuracao-sistema/listar') },
    { route: '/contas-a-pagar/listar', check: usePermission('/contas-a-pagar/listar') },
    { route: '/contas-a-receber/listar', check: usePermission('/contas-a-receber/listar') },
    { route: '/entrada-produto/listar', check: usePermission('/entrada-produto/listar') },
    { route: '/fornecedores/listar', check: usePermission('/fornecedores/listar') },
    { route: '/grupo-produtos/listar', check: usePermission('/grupo-produtos/listar') },
    { route: '/lojas/listar', check: usePermission('/lojas/listar') },
    { route: '/produtos/listar', check: usePermission('/produtos/listar') },
    { route: '/produtos-especiais/listar', check: usePermission('/produtos-especiais/listar') },
    { route: '/promocao-produto/listar', check: usePermission('/promocao-produto/listar') },
    { route: '/venda/cadastrar', check: usePermission('/venda/cadastrar') },
  ];

  // Mapear para o formato esperado pelo menu
  const adjustedList = permissionRoutes.map(item => ({
    route: item.route,
    hasPermission: item.check, // Mapear 'check' para 'hasPermission'
  }));

  // Memoização do menu para evitar reprocessamento
  const dataMenu = getMenuOptions(adjustedList);

  return (
    <Container>
      <Topbar id="topbar" onClick={() => setOpen(prev => !prev)} />
      <Main>
        <div className="main__wrapper">
          <MenuDrawer
            id="MenuDrawer"
            data={dataMenu}
            openDrawer={open}
            onClick={() => setOpen(prev => !prev)}
          />
          <Content>
            <div className="content__wrapper">{children}</div>
          </Content>
        </div>
      </Main>
    </Container>
  );
};

export default Authenticated;
