import { createSlice } from '@reduxjs/toolkit';
import { Fornecedores } from 'modules/fornecedores/types';


type FornecedoresStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: Fornecedores
}

const initialState: FornecedoresStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{      
      idFornecedores: undefined,
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      telefone: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      observacao: '',
      ativo:'false'    
      
    }
}

export const fornecedoresSlice = createSlice({
    name: 'fornecedores',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },        
    }
})

export const fornecedoresActions = fornecedoresSlice.actions
export default fornecedoresSlice.reducer