import { CasaAziri } from "modules/casa-aziri/types"

import { endpoints } from "./api/endpoints/casa-aziri.endpoints"
import { httpGet, httpPut } from "./api/http-methods"
import { CasaAziriApiViewModel } from "./api/models/casa-aziri.model"
import { requestHandler } from "./api/request-handler"
import { mapCasaAziriSelecaoToApi, mapCasaAziriToApi } from "./mappers/casa-aziri.mappers"


export const updateCasaAziri = requestHandler<CasaAziriApiViewModel, CasaAziri>(payload =>
  httpPut(endpoints.atualizar,mapCasaAziriToApi(payload))
)

export const updateSelecaoCasaAziri = requestHandler<CasaAziriApiViewModel, CasaAziri>(payload =>
  httpPut(endpoints.atualizarSelecao,mapCasaAziriSelecaoToApi(payload))
)

export const getCasaAziriById = requestHandler<CasaAziriApiViewModel, string>(id =>
  httpGet(endpoints.obterCasaAziriPorId(id))
)

export const getListCasaAziri = requestHandler<CasaAziriApiViewModel[]>(()=>httpGet(endpoints.listaCasaAziri))

  