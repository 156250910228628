import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {
    cadastrar: `${BASE_URL}/v1/fornecedores`,
    atualizar: `${BASE_URL}/v1/fornecedores`,
    listaFornecedores:`${BASE_URL}/v1/fornecedores/lista`,    
    obterFornecedoresPorId(id: string) {
        return `${BASE_URL}/v1/fornecedores?idFornecedores=${id}`
      },
    excluirFornecedores(id: string) {
        return `${BASE_URL}/v1/fornecedores?idFornecedores=${id}`
      },
}