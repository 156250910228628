import { Usuario } from "modules/usuarios/types";
import { UsuarioApiViewModel } from "services/api/models/usuario.models";
import { toPattern } from "vanilla-masker";

export function mapUsuarioToApi(usu: Usuario) {
    return {
        id: usu.id,
        nome: usu.nome,
        email: usu.email,
        urlFotoUsuario: usu.urlFotoUsuario,
        ativo: usu.ativo,
        cpf: usu.cpf,
        vendedor: usu.vendedor,
        avatar: usu.avatar,
    }
}

export function mapUsuarioToStore(usu: UsuarioApiViewModel) {
    const usuario: Usuario = {
        id: usu.id,
        nome: usu.nome,
        email: usu.email,
        urlFotoUsuario: usu.urlFotoUsuario,
        ativo: usu.ativo,
        cpf: toPattern(usu.cpf, '999.999.999-99'),
        vendedor: usu.vendedor,
        avatar: usu.avatar,
    }

    return usuario
}

export const mapUsuario = mapUsuarioToStore