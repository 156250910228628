import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {
    cadastrar: `${BASE_URL}/v1/produtos`,
    atualizar: `${BASE_URL}/v1/produtos`,
    listaProdutos:`${BASE_URL}/v1/produtos/lista`,    
    obterProdutosPorId(id: string) {
        return `${BASE_URL}/v1/produtos?idProdutos=${id}`
      },
    excluirProdutos(id: string) {
        return `${BASE_URL}/v1/produtos?idProdutos=${id}`
      },
}