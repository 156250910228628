import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.fornecedores

const values = createSelector(state, fornecedores => fornecedores.values)
const origin = createSelector(state, fornecedores => fornecedores.origin)

export const fornecedoresSelectors = {    
    values,
    origin
  }