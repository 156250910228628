import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.loja

const values = createSelector(state, loja => loja.values)
const currentStep = createSelector(state, loja => loja.currentStep)
const completedStep = createSelector(state, loja => loja.completedStep)
const origin = createSelector(state, loja => loja.origin)

export const lojaSelector = {
    completedStep,
    currentStep,    
    values,
    origin
  }