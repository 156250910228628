
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { Usuario } from "modules/usuarios/types"
import { createUsuario, updateUsuario } from "services/usuario.services"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { usuarioSelectors } from './selector'
import { usuarioActions } from './slice'


type CreateUsuarioResult = SagaReturnType<typeof createUsuario>
type UpdateUsuarioResult = SagaReturnType<typeof updateUsuario>

function* handleCreateUsuario() {
    try {
        yield put(usuarioActions.setIsSubmiting(true))
        const origin: string = yield select(usuarioSelectors.origin)
    
        const payload: Usuario = yield select(usuarioSelectors.values)
        const result: CreateUsuarioResult = yield call(createUsuario, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(usuarioActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.id })
        } else {
          history.push('/usuario/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(usuarioActions.setIsSubmiting(false))
      }
}

function* handleUpdateUsuario() {  
    try {
  
        yield put(usuarioActions.setIsSubmiting(true))
        const origin: string = yield select(usuarioSelectors.origin)
    
        const payload: Usuario = yield select(usuarioSelectors.values)
        const result: UpdateUsuarioResult = yield call(updateUsuario, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success('Cadastro atualizado com sucesso'))
        yield put(usuarioActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.id })
        } else {
          history.push('/usuario/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(usuarioActions.setIsSubmiting(false))
      }
}

export default function* usuarioSaga() {
    yield takeLatest(usuarioActions.create.type, handleCreateUsuario)
    yield takeLatest(usuarioActions.update.type, handleUpdateUsuario)
  }
  