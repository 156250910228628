import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.casaAziri

const values = createSelector(state, casaAziri => casaAziri.values)
const origin = createSelector(state, casaAziri => casaAziri.origin)

export const casaAziriSelector = {    
    values,
    origin
  }