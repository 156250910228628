import { Loja } from "modules/loja/types";
import { LojaApiViewModel } from "services/api/models/loja.models";
import { toPattern } from "vanilla-masker";

export function mapLojaToApi(lj: Loja) {
    return {
        id: lj.idLojas,
        nomeLoja: lj.nomeLoja,
        especialLoja: lj.especialLoja,
        razaoSocial: lj.razaoSocial,
        nomeFantasia: lj.nomeFantasia,
        cnpj: lj.cnpj,
        telefone: lj.telefone,
        cep: lj.cep,
        logradouro: lj.logradouro,
        numero	: lj.numero,
        complemento: lj.complemento,
        bairro: lj.bairro,
        cidade: lj.cidade,
        estado: lj.estado,
        observacao: lj.observacao,
        ativo: lj.ativo
        
    }
}

export function mapLojaToStore(lj: LojaApiViewModel) {
    const loja: Loja = {
        idLojas: lj.idLojas,
        nomeLoja: lj.nomeLoja,
        especialLoja: lj.especialLoja,
        razaoSocial: lj.razaoSocial,
        nomeFantasia: lj.nomeFantasia,
        cnpj: toPattern(lj.cnpj, '99.999.999/9999-99'),
        telefone: lj.telefone,
        cep: lj.cep,
        logradouro: lj.logradouro,
        numero	: lj.numero,
        complemento: lj.complemento,
        bairro: lj.bairro,
        cidade: lj.cidade,
        estado: lj.estado,
        observacao: lj.observacao,
        ativo: lj.ativo
    }

    return loja
}
export const mapLoja = mapLojaToStore