import { Produtos } from "modules/produtos/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/produtos.endpoints"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { ProdutosApiViewModel } from "./api/models/produtos.models"
import { requestHandler } from "./api/request-handler"
import { mapProdutosToApi } from "./mappers/produtos.mappers"

export const createProdutos = requestHandler<ProdutosApiViewModel, Produtos>(payload =>
  httpPost(endpoints.cadastrar, mapProdutosToApi(payload))
)

export const updateProdutos = requestHandler<ProdutosApiViewModel, Produtos>(payload =>
  httpPut(endpoints.atualizar,mapProdutosToApi(payload))
)

export const deleteProdutos = requestHandler<ProdutosApiViewModel, string>(id =>
  httpDelete(endpoints.excluirProdutos(id))
)

export const getProdutosById = requestHandler<ProdutosApiViewModel, string>(id =>
  httpGet(endpoints.obterProdutosPorId(id))
)

export const getListProdutosByFilter = requestHandler<ProdutosApiViewModel[], object>(params => {
    const validParams = ['nomeProduto','codigoDeBarra','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaProdutos, searchParamsByValidParams(params, validParams))
  })