import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {    
    atualizar: `${BASE_URL}/v1/casaaziri`,
    atualizarSelecao: `${BASE_URL}/v1/casaaziri/selecionado`,
    listaCasaAziri:`${BASE_URL}/v1/casaaziri/lista`,        
    listaCasaAziriPagina:`${BASE_URL}/v1/casaaziri/lista`,        
    obterCasaAziriPorId(id: string) {
        return `${BASE_URL}/v1/casaaziri?idCasaAziri=${id}`
      },
}