import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LojaFavorita ={
  idLojas: string
  nomeLoja:string
  favorita:boolean
  especialLoja: boolean
  cnpj:string
}

export type LojaLojaFavoritaState = {  
  lojaFavorita:LojaFavorita[]
  idLojaSelecionada:string
}

export type Favorite = {
  idLojas: string  
}

const initialState: LojaLojaFavoritaState = { lojaFavorita: [],idLojaSelecionada:'' }

const lojaFavoritaSlice = createSlice({
  name: 'lojaFavorita',
  initialState,
  reducers: {        
    set: (state: LojaLojaFavoritaState, { payload }: PayloadAction<LojaFavorita[]>) => ({ ...state, lojaFavorita: payload }),    
    load: (state: LojaLojaFavoritaState) => state,
    reset: () => initialState,
    favorite: (
      state: LojaLojaFavoritaState,
      { payload: _payload }: PayloadAction<{ idLoja: string; cod: string; favoritar: boolean }>
    ) => state,

    // faz update do LojaState no backend e depois 
    // atualiza o estado da aplicação (LojaState)
    updateSelecionado: (state: LojaLojaFavoritaState, { payload: _payload}: PayloadAction<{ idLoja: string; cod: string; }>) => ({...state, idLoja: _payload}),

    // apenas autaliza o LojaState no estado da aplicação. Utilizado por métodos que
    // antes buscam a informação atual no backend, como o load.
    setSelecionado: (state: LojaLojaFavoritaState, { payload }: PayloadAction<string>) => ({...state, idLojaSelecionada: payload}),
    },
  },
)

export const lojaFavoritaActions = lojaFavoritaSlice.actions

export default lojaFavoritaSlice.reducer
