
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { Loja } from "modules/loja/types"
import { createLoja, updateLoja } from "services/loja.services"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { lojaSelector } from './selector'
import { lojaActions } from './slice'


type CreateLojaResult = SagaReturnType<typeof createLoja>
type UpdateLojaResult = SagaReturnType<typeof updateLoja>

function* handleCreateLoja() {
    try {
        yield put(lojaActions.setIsSubmiting(true))
        const origin: string = yield select(lojaSelector.origin)
    
        const payload: Loja = yield select(lojaSelector.values)
        const result: CreateLojaResult = yield call(createLoja, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(lojaActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.idLojas })
        } else {
          history.push('/lojas/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(lojaActions.setIsSubmiting(false))
      }
}

function* handleUpdateLoja() {  
    try {
        
        yield put(lojaActions.setIsSubmiting(true))
        const origin: string = yield select(lojaSelector.origin)

        const payload: Loja = yield select(lojaSelector.values)
        
        const result: UpdateLojaResult = yield call(updateLoja, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(lojaActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idLojas })
        } else {
          history.push('/lojas/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(lojaActions.setIsSubmiting(false))
      }
}

export default function* lojaSaga() {
    yield takeLatest(lojaActions.create.type, handleCreateLoja)
    yield takeLatest(lojaActions.update.type, handleUpdateLoja)
  }
  