import { CasaAziri } from "modules/casa-aziri/types"
import { CasaAziriApiViewModel } from "services/api/models/casa-aziri.model"


export function mapCasaAziriToApi(x: CasaAziri) {    
    return {
        idCasaAziri:x.idCasaAziri,
        numero:x.numero,
        valor:x.valor,
        nome:x.nome,
        selecionado:x.selecionado,
        dataPagamento:x.dataPagamento,
        confirmado:x.confirmado,
        dataConfirmacao:x.dataConfirmacao,
          }
}

export function mapCasaAziriSelecaoToApi(x: CasaAziri) {    
  return {
      idCasaAziri:x.idCasaAziri,      
        }
}

export function mapCasaAziriToStore(x: CasaAziriApiViewModel) {    
    const casa: CasaAziri = {
      idCasaAziri:x.idCasaAziri,
      numero:x.numero,
      valor:x.valor,
      nome:x.nome,
      selecionado:x.selecionado,
      dataPagamento:x.dataPagamento,
      confirmado:x.confirmado,
      dataConfirmacao:x.dataConfirmacao,
    }
    return casa
}

export const mapCasaAziri = mapCasaAziriToStore