import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const lojaState = (state: AppState) => state.lojaFavorita.lojaFavorita

const lojaFiltrada = (state:AppState) => {   
  if(state.lojaFavorita.idLojaSelecionada)  {    
    return state.lojaFavorita.lojaFavorita.find(x => x.idLojas === state.lojaFavorita.idLojaSelecionada)
  }
  else if(state.lojaFavorita.lojaFavorita.find(s=>s.favorita)){
    return state.lojaFavorita.lojaFavorita.find(x => x.favorita)
  }
  else{
    return state.lojaFavorita.lojaFavorita[0]
  }  
}

const loja = createSelector(lojaState, loja => loja)
const idSelecionada = createSelector(lojaFiltrada, loja => loja?.idLojas)
const lojaSelecionada = createSelector(lojaFiltrada, loja => loja)


export const lojaFavoritaSelectors = { loja, idSelecionada, lojaSelecionada  }
export default lojaFavoritaSelectors
