import { Produtos } from "modules/produtos/types";
import { ProdutosApiViewModel } from "services/api/models/produtos.models";


export function mapProdutosToApi(x: Produtos) {    
    return {
            idProdutos: x.idProdutos,
            idGrupoProdutos: x.grupoProduto.value,        
            idLojas: x.loja.value,        
            nomeProduto: x.nomeProduto,        
            codigoEAN: x.codigoEAN,        
            codigoDeBarra: x.codigoDeBarra,        
            urlImagem: x.urlImagem,        
            estoqueAtual: x.estoqueAtual, 
            estoqueMinimo: x.estoqueMinimo,       
            precoDeVenda: x.precoDeVenda, 
            nmImagem: x.nmImagem,       
            base64Imagem:x.base64Imagem,
            ativo: x.ativo
          }
}

export function mapProdutosToStore(x: ProdutosApiViewModel) {    
    const produtos: Produtos = {
        idProdutos: x.idProdutos,
        idGrupoProdutos: x.idGrupoProdutos,
        idLojas: x.idLojas,
        nomeProduto: x.nomeProduto,
        codigoEAN: x.codigoEAN,
        codigoDeBarra: x.codigoDeBarra,
        urlImagem: x.urlImagem,
        estoqueAtual: x.estoqueAtual,
        estoqueMinimo: x.estoqueMinimo,
        precoDeVenda: x.precoDeVenda,
        nmImagem: '',
        base64Imagem: '',
        ativo: x.ativo === 'true' ? 'true' : 'false',
        lojas: x.lojas,
        grupoProdutos: x.grupoProdutos,
        grupoProduto: {
            value: "",
            label: ""
        },
        loja: {
            value: "",
            label: ""
        }
    }

    return produtos
}
export const mapProdutos = mapProdutosToStore