
import { GrupoProdutos } from "modules/grupo-produtos/types";
import { GrupoProdutosApiViewModel } from "services/api/models/grupo-produtos.models";


export function mapGrupoProdutosToApi(x: GrupoProdutos) {
    return {
        idGrupoProdutos: x.idGrupoProdutos,
        nomeGrupoProdutos: x.nomeGrupoProdutos,        
        ativo: x.ativo        
    }
}

export function mapGrupoProdutosToStore(x: GrupoProdutosApiViewModel) {
    const grupoProdutos: GrupoProdutos = {
      idGrupoProdutos: x.idGrupoProdutos,
      nomeGrupoProdutos: x.nomeGrupoProdutos,        
      ativo: x.ativo === 'true' ? 'true':'false'
    }

    return grupoProdutos
}
export const mapGrupoProdutos = mapGrupoProdutosToStore