import React from 'react'

import useWindowDimensions from 'hooks/useWindowDimensions'
import logo from 'styles/assets/logo_250.png'
import breakpoints from 'styles/breakpoints'

import { Grid } from '@material-ui/core'

import {
  Container, Infos, LayoutWrapper, ResponsiveImage, Version
} from './styles'

const CasaAziri: React.FC = ({ children }) => {
  const { width } = useWindowDimensions()

  return (
    <LayoutWrapper>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Container>
            <ResponsiveImage src={logo} alt="Tenda de Hórus logo" />
            {children}
            <div>
              <Infos>{`Em caso de dúvidasa:`}</Infos>              
              <Infos>{`• Telefone: (61) 3703-9999`}</Infos>              
              <Version></Version>
            </div>
          </Container>
        </Grid>
        {width >= breakpoints.medium && <Grid item md={8}></Grid>}
      </Grid>
    </LayoutWrapper>
  )
}

export default CasaAziri
