
import { Fornecedores } from "modules/fornecedores/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/fornecedores.endpoints"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { FornecedoresApiViewModel } from "./api/models/fornecedores.models"
import { requestHandler } from "./api/request-handler"
import { mapFornecedoresToApi } from "./mappers/fornecedores.mappers"

export const createFornecedores = requestHandler<FornecedoresApiViewModel,Fornecedores>(payload =>
  httpPost(endpoints.cadastrar, mapFornecedoresToApi(payload))
)

export const updateFornecedores = requestHandler<FornecedoresApiViewModel,Fornecedores>(payload =>
  httpPut(endpoints.atualizar,mapFornecedoresToApi(payload))
)

export const deleteFornecedores = requestHandler<FornecedoresApiViewModel, string>(id =>
  httpDelete(endpoints.excluirFornecedores(id))
)

export const getFornecedoresById = requestHandler<FornecedoresApiViewModel, string>(id =>
  httpGet(endpoints.obterFornecedoresPorId(id))
)

export const getListFornecedoresByFilter = requestHandler<FornecedoresApiViewModel[], object>(params => {
    const validParams = ['razaoSocial','nomeFantasia','cnpj','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaFornecedores, searchParamsByValidParams(params, validParams))
  })