import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.banco

const values = createSelector(state, banco => banco.values)
const origin = createSelector(state, banco => banco.origin)

export const bancoSelectors = {    
    values,
    origin
  }