
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { Banco } from "modules/banco/types"
import { createBanco, updateBanco } from "services/banco.service"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { bancoSelectors } from './selector'
import { bancoActions } from './slice'


type CreateBancoResult = SagaReturnType<typeof createBanco>
type UpdateBancoResult = SagaReturnType<typeof updateBanco>

function* handleCreateBanco() {
    try {
        yield put(bancoActions.setIsSubmiting(true))
        const origin: string = yield select(bancoSelectors.origin)
    
        const payload: Banco = yield select(bancoSelectors.values)
        const result: CreateBancoResult = yield call(createBanco, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(bancoActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.idBancos })
        } else {
          history.push('/bancos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(bancoActions.setIsSubmiting(false))
      }
}

function* handleUpdateBanco() {  
    try {
        
        yield put(bancoActions.setIsSubmiting(true))
        const origin: string = yield select(bancoSelectors.origin)

        const payload: Banco = yield select(bancoSelectors.values)
        
        const result: UpdateBancoResult = yield call(updateBanco, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(bancoActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idBancos })
        } else {
          history.push('/bancos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(bancoActions.setIsSubmiting(false))
      }
}

export default function* bancoSaga() {
    yield takeLatest(bancoActions.create.type, handleCreateBanco)
    yield takeLatest(bancoActions.update.type, handleUpdateBanco)
  }
  