import styled from 'styled-components'
import colors from 'styles/colors'
import fonts from 'styles/fonts/fonts'

export const LayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;  
  background-size: cover;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  background-color: ${colors.white};
  padding: 10px ;
`

export const Infos = styled.p`
  font-family: ${fonts.fontFamilyRaleway};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 154.3%;
  color: ${colors.grey};
`

export const Version = styled.h1`
  font-family: ${fonts.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #80a3b4;
`

export const AnimationWrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
`

export const Animation = styled.div`
  position: relative;
  mix-blend-mode: color-dodge;
  filter: blur(14px);
`
export const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  width: auto;
  object-fit: cover; 
  display: block; 
  margin: 0 auto;
`
