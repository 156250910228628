import { createSlice } from '@reduxjs/toolkit';
import { Banco } from 'modules/banco/types';


type BancoStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: Banco
}

const initialState: BancoStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{
      idBancos: undefined,
      nomeBancos: '',      
      ativo: 'false',      
    }
}

export const bancoSlice = createSlice({
    name: 'banco',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },        
    }
})

export const bancoActions = bancoSlice.actions
export default bancoSlice.reducer