import { createSlice } from '@reduxjs/toolkit';
import { Produtos } from 'modules/produtos/types';

type ProdutosStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: Produtos
}

const initialState: ProdutosStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{
        idProdutos: undefined,
        idGrupoProdutos: '',
        idLojas: '',
        nomeProduto: '',
        codigoEAN: '',
        codigoDeBarra: '',
        urlImagem: '',
        estoqueAtual: '',
        estoqueMinimo: '',
        precoDeVenda: '',
        nmImagem: '',
        base64Imagem: '',
        ativo: 'false',
        lojas: '',
        grupoProdutos: '',
        grupoProduto: {
            value: '',
            label: ''
        },
        loja: {
            value: '',
            label: ''
        }
    }
}

export const produtosSlice = createSlice({
    name: 'produtos',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        setCompletedStep(state, action) {
            state.completedStep = action.payload
        },
    }
})

export const produtosActions = produtosSlice.actions
export default produtosSlice.reducer