import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {
    cadastrar: `${BASE_URL}/v1/lojas`,
    atualizar: `${BASE_URL}/v1/lojas`,
    listaLoja:`${BASE_URL}/v1/lojas/lista`,    
    obterLojaPorId(id: string) {
        return `${BASE_URL}/v1/lojas?idLoja=${id}`
      },
    excluirLoja(id: string) {
        return `${BASE_URL}/v1/lojas?idLoja=${id}`
      },
}