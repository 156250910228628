
import { Loja } from "modules/loja/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/loja.endpoints"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { LojaApiViewModel } from "./api/models/loja.models"
import { requestHandler } from "./api/request-handler"
import { mapLojaToApi } from "./mappers/loja.mappers"



export const createLoja = requestHandler<LojaApiViewModel, Loja>(payload =>
    httpPost(endpoints.cadastrar, mapLojaToApi(payload))
  )
  
  export const updateLoja = requestHandler<LojaApiViewModel, Loja>(payload =>
    httpPut(endpoints.atualizar,mapLojaToApi(payload))
  )
  
  export const deleteLoja = requestHandler<LojaApiViewModel, string>(id =>
    httpDelete(endpoints.excluirLoja(id))
  )

  export const getLojaById = requestHandler<LojaApiViewModel, string>(id =>
    httpGet(endpoints.obterLojaPorId(id))
  )

  export const getLojaByFilters = requestHandler<LojaApiViewModel[], object>(params => {
    const validParams = ['nome','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaLoja, searchParamsByValidParams(params, validParams))
  })