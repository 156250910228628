import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { Fornecedores } from "modules/fornecedores/types"
import { createFornecedores, updateFornecedores } from "services/fornecedores.services"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { fornecedoresSelectors } from './selector'
import { fornecedoresActions } from './slice'


type CreateFornecedoresResult = SagaReturnType<typeof createFornecedores>
type UpdateFornecedoresResult = SagaReturnType<typeof updateFornecedores>

function* handleCreateFornecedores() {
    try {
 
        yield put(fornecedoresActions.setIsSubmiting(true))
        const origin: string = yield select(fornecedoresSelectors.origin)
    
        const payload: Fornecedores = yield select(fornecedoresSelectors.values)
        const result: CreateFornecedoresResult = yield call(createFornecedores, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(fornecedoresActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.idFornecedores })
        } else {
          history.push('/fornecedores/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(fornecedoresActions.setIsSubmiting(false))
      }
}

function* handleUpdateFornecedores() {  
    try {      
        yield put(fornecedoresActions.setIsSubmiting(true))
        const origin: string = yield select(fornecedoresSelectors.origin)

        const payload: Fornecedores = yield select(fornecedoresSelectors.values)
        
        const result: UpdateFornecedoresResult = yield call(updateFornecedores, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(fornecedoresActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idFornecedores })
        } else {
          history.push('/fornecedores/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(fornecedoresActions.setIsSubmiting(false))
      }
}

export default function* fornecedoresSaga() {
    yield takeLatest(fornecedoresActions.create.type, handleCreateFornecedores)
    yield takeLatest(fornecedoresActions.update.type, handleUpdateFornecedores)
  }
  