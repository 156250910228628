import { Banco } from "modules/banco/types";
import { BancoApiViewModel } from "services/api/models/banco.models";


export function mapBancoToApi(x: Banco) {
    return {
        idBancos: x.idBancos,
        nomeBancos: x.nomeBancos,        
        ativo: x.ativo
        
    }
}

export function mapBancoToStore(x: BancoApiViewModel) {
    const banco: Banco = {
      idBancos: x.idBancos,
      nomeBancos: x.nomeBancos,        
      ativo: x.ativo === 'true' ? 'true':'false'
    }

    return banco
}
export const mapBanco = mapBancoToStore