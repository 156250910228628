
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { Produtos } from "modules/produtos/types"
import { createProdutos, updateProdutos } from "services/produtos.services"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { produtosSelector } from './selector'
import { produtosActions } from './slice'


type CreateProdutosResult = SagaReturnType<typeof createProdutos>
type UpdateProdutosResult = SagaReturnType<typeof updateProdutos>

function* handleCreateProdutos() {
    try {
        yield put(produtosActions.setIsSubmiting(true))
        const origin: string = yield select(produtosSelector.origin)
    
        const payload: Produtos = yield select(produtosSelector.values)
        
        const result: CreateProdutosResult = yield call(createProdutos, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(produtosActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.idProdutos })
        } else {
          history.push('/produtos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(produtosActions.setIsSubmiting(false))
      }
}

function* handleUpdateProdutos() {  
    try {        
        yield put(produtosActions.setIsSubmiting(true))
        const origin: string = yield select(produtosSelector.origin)

        const payload: Produtos = yield select(produtosSelector.values)
        
        const result: UpdateProdutosResult = yield call(updateProdutos, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(produtosActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idProdutos })
        } else {
          history.push('/produtos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(produtosActions.setIsSubmiting(false))
      }
}

export default function* produtosSaga() {
    yield takeLatest(produtosActions.create.type, handleCreateProdutos)
    yield takeLatest(produtosActions.update.type, handleUpdateProdutos)
  }
  