import { GrupoProdutos } from "modules/grupo-produtos/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/grupo-produtos.endopoint"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { GrupoProdutosApiViewModel } from "./api/models/grupo-produtos.models"
import { requestHandler } from "./api/request-handler"
import { mapGrupoProdutosToApi } from "./mappers/grupo-produtos.mappers"

export const createGrupoProdutos = requestHandler<GrupoProdutosApiViewModel, GrupoProdutos>(payload =>
  httpPost(endpoints.cadastrar, mapGrupoProdutosToApi(payload))
)

export const updateGrupoProdutos = requestHandler<GrupoProdutosApiViewModel, GrupoProdutos>(payload =>
  httpPut(endpoints.atualizar,mapGrupoProdutosToApi(payload))
)

export const deleteGrupoProdutos = requestHandler<GrupoProdutosApiViewModel, string>(id =>
  httpDelete(endpoints.excluirGrupoProdutos(id))
)

export const getGrupoProdutosById = requestHandler<GrupoProdutosApiViewModel, string>(id =>
  httpGet(endpoints.obterGrupoProdutosPorId(id))
)

export const getListGrupoProdutosByFilter = requestHandler<GrupoProdutosApiViewModel[], object>(params => {
    const validParams = ['nomeGrupoProdutos','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaGrupoProdutos, searchParamsByValidParams(params, validParams))
  })