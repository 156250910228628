import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.grupoProdutos

const values = createSelector(state, gp => gp.values)
const origin = createSelector(state, gp => gp.origin)

export const grupoProdutosSelectors = {    
    values,
    origin
  }