import { createSlice } from '@reduxjs/toolkit';
import { Loja } from 'modules/loja/types';

type LojaStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: Loja
}

const initialState: LojaStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{
      idLojas: undefined,
      nomeLoja: '',
      nomeFantasia: '',
      ativo: 'false',
      especialLoja: 'false',
      razaoSocial: '',
      cnpj: '',
      telefone:'',
      cep:'',
      logradouro:'',
      numero:'',
      complemento:'',
      bairro:'',
      cidade:'',
      estado:'',
      observacao:''
    }
}

export const lojaSlice = createSlice({
    name: 'loja',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        setCompletedStep(state, action) {
            state.completedStep = action.payload
        },
    }
})

export const lojaActions = lojaSlice.actions
export default lojaSlice.reducer