import { combineReducers } from '@reduxjs/toolkit'
import alert from 'store/alert/duck'
import atividadeNProcedimental from 'store/atividade-nao-procedimental/slice'
import banco from 'store/banco/slice'
import casaAziri from 'store/casa-aziri/slice'
import drawer from 'store/drawer/duck'
import equipeDistribuicao from 'store/equipe-distribuicao/slice'
import errors from 'store/errors/duck'
import fornecedores from 'store/fornecedores/slice'
import grupoProdutos from 'store/grupo-produtos/slice'
import loader from 'store/loader/duck'
import loja from 'store/loja/slice'
import lojaFavorita from 'store/lojaFavorita/slice'
import modals from 'store/modals/duck'
import navigation from 'store/navigation/slice'
import permissions from 'store/permissions/duck'
import pessoaFisica from 'store/pessoa-fisica/slice'
import pessoaJuridica from 'store/pessoa-juridica/slice'
import processoProcedimento from 'store/processo-procedimento/slice'
import produtos from 'store/produtos/slice'
import settings from 'store/settings/duck'
import user from 'store/user/duck'
import usuario from 'store/usuario/slice'


const rootReducer = combineReducers({
  drawer,
  errors,
  loader,
  modals,
  user,
  permissions,
  lojaFavorita,
  alert,
  settings,
  processoProcedimento,
  pessoaFisica,
  pessoaJuridica,
  atividadeNProcedimental,
  navigation,
  equipeDistribuicao,
  usuario,
  loja,
  banco,
  fornecedores,
  grupoProdutos,
  produtos,
  casaAziri
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
