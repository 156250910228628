import { Fornecedores } from "modules/fornecedores/types";
import { FornecedoresApiViewModel } from "services/api/models/fornecedores.models";
import { toPattern } from "vanilla-masker";


export function mapFornecedoresToApi(x: Fornecedores) {
    return {
      idFornecedores:x.idFornecedores,
      razaoSocial: x.razaoSocial,
      nomeFantasia: x.nomeFantasia,
      cnpj: x.cnpj,
      telefone: x.telefone,
      cep: x.cep,
      logradouro: x.logradouro,
      numero: x.numero,
      complemento: x.complemento,
      bairro: x.bairro,
      cidade: x.cidade,
      estado: x.estado,
      observacao: x.observacao,
      ativo: x.ativo        
    }
}

export function mapFornecedoresToStore(x: FornecedoresApiViewModel) {
    const fornecedores: Fornecedores = {
      idFornecedores:x.idFornecedores,
      razaoSocial: x.razaoSocial,
      nomeFantasia: x.nomeFantasia,
      cnpj: toPattern(x.cnpj, '99.999.999/9999-99'),
      telefone: x.telefone,
      cep: x.cep,
      logradouro: x.logradouro,
      numero: x.numero,
      complemento: x.complemento,
      bairro: x.bairro,
      cidade: x.cidade,
      estado: x.estado,
      observacao: x.observacao,      
      ativo: x.ativo === 'true' ? 'true':'false'
    }

    return fornecedores
}
export const mapFornecedores = mapFornecedoresToStore