
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { CasaAziri } from "modules/casa-aziri/types"
import { updateCasaAziri, updateSelecaoCasaAziri } from "services/casa-aziri.service"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { casaAziriSelector } from "./selector"
import { casaAziriActions } from "./slice"


type UpdateCasaAziriResult = SagaReturnType<typeof updateCasaAziri>
type UpdateSelecaoCasaAziriResult = SagaReturnType<typeof updateSelecaoCasaAziri>

function* handleUpdateCasaAziri() {  
    try {        
        yield put(casaAziriActions.setIsSubmiting(true))
        const origin: string = yield select(casaAziriSelector.origin)

        const payload: CasaAziri = yield select(casaAziriSelector.values)
        
        const result: UpdateCasaAziriResult = yield call(updateCasaAziri, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(casaAziriActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idCasaAziri })
        } else {
          history.push('/casa-aziri/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(casaAziriActions.setIsSubmiting(false))
      }
}

function* handleUpdateSelecaoCasaAziri() {  
  try {        
      yield put(casaAziriActions.setIsSubmiting(true))
      const origin: string = yield select(casaAziriSelector.origin)

      const payload: CasaAziri = yield select(casaAziriSelector.values)
      
      const result: UpdateSelecaoCasaAziriResult = yield call(updateSelecaoCasaAziri, payload)
  
      if (!result.success) {
        throw new Error(result.errorMessage)
      }
  
      yield put(alert.success(message.MSG0004))
      yield put(casaAziriActions.reset())
  
      if (origin) {
        history.push(origin, { selectedId: payload.idCasaAziri })
      } else {
        history.push('/casa-aziri')
      }
    } catch (error) {
      if (error instanceof Error) {
        yield put(alert.error(error.message))
      }
    } finally {
      yield put(casaAziriActions.setIsSubmiting(false))
    }
}


export default function* casaAziriSaga() {    
  yield takeLatest(casaAziriActions.update.type, handleUpdateCasaAziri)
  yield takeLatest(casaAziriActions.updateSelecao.type, handleUpdateSelecaoCasaAziri)
  }
  