import { all } from 'redux-saga/effects'

import atividadeNaoProcedimentalSaga from './atividade-nao-procedimental/saga'
import bancoSaga from './banco/saga'
import casaAziriSaga from './casa-aziri/saga'
import equipeDistribuicaoSaga from './equipe-distribuicao/saga'
import fornecedoresSaga from './fornecedores/saga'
import grupoProdutosSaga from './grupo-produtos/saga'
import lojaSaga from './loja/saga'
import lojaFavoritaSaga from './lojaFavorita/saga'
import formsStateSaga from './navigation/saga'
import permissionSaga from './permissions/saga'
import pessoaFisicaSaga from './pessoa-fisica/saga'
import pessoaJuridicaSaga from './pessoa-juridica/saga'
import processoProcedimentoSaga from './processo-procedimento/saga'
import produtosSaga from './produtos/saga'
import settingsSaga from './settings/saga'
import userSaga from './user/saga'
import usuarioSaga from './usuario/saga'

export default function* rootSaga(): Generator {
  yield all([
    userSaga(),
    settingsSaga(),
    lojaFavoritaSaga(),
    permissionSaga(),
    processoProcedimentoSaga(),
    pessoaFisicaSaga(),
    pessoaJuridicaSaga(),
    atividadeNaoProcedimentalSaga(),
    formsStateSaga(),
    equipeDistribuicaoSaga(),
    usuarioSaga(),
    lojaSaga(),
    bancoSaga(),
    fornecedoresSaga(),
    grupoProdutosSaga(),
    produtosSaga(),
    casaAziriSaga()
  ])
}
