import { Banco } from "modules/banco/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/banco.endpoints"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { BancoApiViewModel } from "./api/models/banco.models"
import { requestHandler } from "./api/request-handler"
import { mapBancoToApi } from "./mappers/banco.mappers"

export const createBanco = requestHandler<BancoApiViewModel, Banco>(payload =>
  httpPost(endpoints.cadastrar, mapBancoToApi(payload))
)

export const updateBanco = requestHandler<BancoApiViewModel, Banco>(payload =>
  httpPut(endpoints.atualizar,mapBancoToApi(payload))
)

export const deleteBanco = requestHandler<BancoApiViewModel, string>(id =>
  httpDelete(endpoints.excluirBanco(id))
)

export const getBancoById = requestHandler<BancoApiViewModel, string>(id =>
  httpGet(endpoints.obterBancoPorId(id))
)

export const getAllBancos = requestHandler<BancoApiViewModel[], object>(params => {
    const validParams = ['nomeBancos','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaBanco, searchParamsByValidParams(params, validParams))
  })