import { Permission } from 'store/permissions/duck'
import { UserState } from 'store/user/duck'

import routes from './api/endpoints/login.endpoints'
import { httpPost, httpGet, httpPut } from './api/http-methods'
import { requestHandler } from './api/request-handler'

type LoginParams = {
  email: string
  password: string
}

type FavoritarParams = {
  idLoja: string
  cod: string
  favoritar: boolean
}

type SelecionarParams = {
  cod: string
  idLoja:string
}

type Favoritar = {
  idOrgaoUnidadeFavoritoLogin: number
  cdUsuario: number
  cdOrgaoUnidade: number
}

type Selecionar = {
  idLojas:string
}

type GetSelecionado = {
  idLojas: string
}

type PermissoesParams = {
  cod: string
}

type VendendorParams = {
  cod: string
}

type LojasVendendor={
    idLojas: string
    nomeLoja: string
    favorita:boolean
    especialLoja: boolean
    cnpj: string
}

export const login = requestHandler<UserState, LoginParams>(params => httpPost(routes.login, params))

export const getPermissionsByGroups = requestHandler<Permission[], PermissoesParams>(params =>
  httpGet(routes.getPermissions,  params)
)

export const getLojasVendendor = requestHandler<LojasVendendor[],VendendorParams>(params => 
  httpGet(routes.getLojasVendendor,  params)
)

export const getLojaVendendorSelecionada = requestHandler<GetSelecionado, VendendorParams>(params =>
  httpGet(routes.getLojaVendendorSelecionada, params)
)

export const favoritarLoja = requestHandler<Favoritar, FavoritarParams>(params =>
  httpPost(routes.favoritarLoja, params)
)

export const selecionarLojaVendendor= requestHandler<Selecionar, SelecionarParams>(params =>
  httpPut(routes.selecionarLojaVendendor, params)
)

