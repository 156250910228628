import React, { memo } from 'react'

import CasaAziriLayout from 'layout/CasaAziri/CasaAziri'
import { useRoutes } from 'routes/guest.routes'

import RouterSwitch from './RouterSwitch'

const CasaAziriScreenRouter: React.FC = () => {
  const { routes } = useRoutes()

  return (
    <CasaAziriLayout>
      <RouterSwitch routes={routes} />
    </CasaAziriLayout>
  )
}

export default memo(CasaAziriScreenRouter)
