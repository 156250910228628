
import { call, put, SagaReturnType, select, takeLatest } from "redux-saga/effects"

import message from 'constants/message'
import { GrupoProdutos } from 'modules/grupo-produtos/types';
import { createGrupoProdutos, updateGrupoProdutos } from "services/grupo-produtos.services"
import { alert } from 'store/alert/duck'
import history from 'store/history'

import { grupoProdutosSelectors } from './selector'
import { grupoProdutosActions } from './slice'


type CreateGrupoProdutosResult = SagaReturnType<typeof createGrupoProdutos>
type UpdateGrupoProdutosResult = SagaReturnType<typeof updateGrupoProdutos>

function* handleCreateGrupoProdutos() {
    try {
        yield put(grupoProdutosActions.setIsSubmiting(true))
        const origin: string = yield select(grupoProdutosSelectors.origin)
    
        const payload: GrupoProdutos = yield select(grupoProdutosSelectors.values)
        const result: CreateGrupoProdutosResult = yield call(createGrupoProdutos, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0001))
        yield put(grupoProdutosActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: result.data.idGrupoProdutos })
        } else {
          history.push('/grupo-produtos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(grupoProdutosActions.setIsSubmiting(false))
      }
}

function* handleUpdateGrupoProdutos() {  
    try {
        
        yield put(grupoProdutosActions.setIsSubmiting(true))
        const origin: string = yield select(grupoProdutosSelectors.origin)

        const payload: GrupoProdutos = yield select(grupoProdutosSelectors.values)
        
        const result: UpdateGrupoProdutosResult = yield call(updateGrupoProdutos, payload)
    
        if (!result.success) {
          throw new Error(result.errorMessage)
        }
    
        yield put(alert.success(message.MSG0004))
        yield put(grupoProdutosActions.reset())
    
        if (origin) {
          history.push(origin, { selectedId: payload.idGrupoProdutos })
        } else {
          history.push('/grupo-produtos/listar')
        }
      } catch (error) {
        if (error instanceof Error) {
          yield put(alert.error(error.message))
        }
      } finally {
        yield put(grupoProdutosActions.setIsSubmiting(false))
      }
}

export default function* grupoProdutosSaga() {
    yield takeLatest(grupoProdutosActions.create.type, handleCreateGrupoProdutos)
    yield takeLatest(grupoProdutosActions.update.type, handleUpdateGrupoProdutos)
  }
  