import { Usuario } from "modules/usuarios/types"
import { searchParamsByValidParams } from "utils/helpers"

import { endpoints } from "./api/endpoints/usuario.endpoints"
import { httpDelete, httpGet, httpPost, httpPut } from "./api/http-methods"
import { UsuarioApiViewModel } from "./api/models/usuario.models"
import { requestHandler } from "./api/request-handler"
import { mapUsuarioToApi } from "./mappers/usuario.mappers"

export const createUsuario = requestHandler<UsuarioApiViewModel, Usuario>(payload =>
    httpPost(endpoints.cadastrar, mapUsuarioToApi(payload))
  )
  
  export const updateUsuario = requestHandler<UsuarioApiViewModel, Usuario>(payload =>
    httpPut(endpoints.atualizar,mapUsuarioToApi(payload))
  )
  
  export const deleteUsuario = requestHandler<UsuarioApiViewModel, string>(id =>
    httpDelete(endpoints.excluirUsuario(id))
  )

  export const getUsuarioById = requestHandler<UsuarioApiViewModel, string>(id =>
    httpGet(endpoints.obterUsuarioPorId(id))
  )

  export const getUsuarioByFilters = requestHandler<UsuarioApiViewModel[], object>(params => {
    const validParams = ['email', 'nome', 'cpf','metaData.pageNumber','metaData.pageSize']    

    return httpGet(endpoints.listaUsuarios, searchParamsByValidParams(params, validParams))
  })