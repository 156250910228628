import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {
    cadastrar: `${BASE_URL}/v1/user`,
    atualizar: `${BASE_URL}/v1/user`,
    listaUsuarios:`${BASE_URL}/v1/user/lista-usuarios`,
    cpfCadastrado(cpf: string) {
        return `${BASE_URL}/v1/user?cpf=${cpf}`
      },
    obterUsuarioPorId(id: string) {
        return `${BASE_URL}/v1/user?id=${id}`
      },
    excluirUsuario(id: string) {
        return `${BASE_URL}/v1/user?id=${id}`
      },
}